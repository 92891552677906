import { FrozenRowsModule } from "tabulator-tables";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    super.connect();
    // add your code here, if applicable
  }

  openSubMenuForSmallScreens(e) {
    const parent = e.target.closest(".btn-group-wrapper");
    parent.querySelector(".btn-group").classList.toggle("hide-small");
  }

  openSubMenu(e) {
    const parent = e.target.closest(".btn-group-wrapper");
    document
      .querySelectorAll(".btn-group:not(.btn-menu-on-small)")
      .forEach((g) => {
        if (g == parent.querySelector(".btn-group")) {
          parent.querySelector(".btn-group").classList.toggle("hide");
        } else {
          g.classList.add("hide");
        }
      });
  }
  closeSubMenuOnMenuSelect(e) {
    const parent = e.target.closest(".btn-group-wrapper");
    parent.querySelector(".btn-group").classList.add("hide");
  }

  change_status(e) {
    this.changeStatus(e, "Events#event_status");
  }

  async quick_change_status(e) {
    return await this.changeStatus(e, "Events#quick_event_status").then((e) =>
      console.log("change")
    );
  }

  async changeStatus(e, reflex) {
    const target = e.target;
    let canChangeStatus = true;
    if (e.target.value == "zikuy") {
      canChangeStatus = await this.isConfirm(this.confirmList());
    }
    if (canChangeStatus) {
      const status =
        target.value + "-bg custom-bg btn btn-secondary select-status";
      target.className = status;
      await this.stimulate(reflex, target.dataset.event, target.value)
        .then((rr) => {
          if (target.value == "ordered" || target.value == "payed") {
            confetti();
          }
          target.dataset.old = target.value;
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      target.value = target.dataset.old;
    }
  }

  async openSelectModal(e, options) {
    return await this.isSelected(options);
  }
  async openInputModal(e, type, options) {
    switch (type) {
      case "enum":
        return await this.openSelectModal(e, options);
      default:
        return await this.setInput(type);
    }
  }
  async setInput(type) {
    const dialog = this.buildModal(type, "input");
    const input = dialog.querySelector("#input");
    const okBtn = dialog.querySelector("#okBtn");
    const cancelBtn = dialog.querySelector("#cancelBtn");
    function myPromiseGenerator() {
      return new Promise((acc) => {
        function closeDialog(e) {
          if (e.target != okBtn && e.target != dialog && e.target != cancelBtn)
            return;
          dialog.removeEventListener("click", closeDialog);
          dialog.remove();
          let res = false;
          if (e.target == okBtn && input.type != "checkbox") {
            res = input.value;
          }
          if (e.target == okBtn && input.type == "checkbox") {
            res = input.checked ? "on" : "off";
          }
          acc(res);
        }
        dialog.addEventListener("click", (event) => {
          closeDialog(event);
        });
      });
    }
    return await myPromiseGenerator();
  }

  async isSelected(options) {
    const dialog = this.buildModal(options, "select");
    const select = dialog.querySelector("#select");
    const okBtn = dialog.querySelector("#okBtn");
    const cancelBtn = dialog.querySelector("#cancelBtn");
    function myPromiseGenerator() {
      return new Promise((acc) => {
        function closeDialog(e) {
          if (e.target != okBtn && e.target != dialog && e.target != cancelBtn)
            return;
          dialog.removeEventListener("click", closeDialog);
          dialog.remove();
          let res = false;
          if (e.target == okBtn) {
            res = select.value;
          }
          acc(res);
        }
        dialog.addEventListener("click", (event) => {
          closeDialog(event);
        });
      });
    }
    return await myPromiseGenerator();
  }

  async isConfirm(text) {
    const dialog = this.buildModal(text, "confirm");
    const yesBtn = dialog.querySelector("#yesBtn");
    const noBtn = dialog.querySelector("#noBtn");
    const ipts = dialog.querySelectorAll("input");
    function myPromiseGenerator() {
      return new Promise((acc) => {
        function closeDialog(e) {
          let isAllCheck = true;
          ipts.forEach((ipt) => {
            if (!ipt.checked) {
              isAllCheck = false;
            }
          });
          yesBtn.disabled = !isAllCheck;
          if (e.target != noBtn && e.target != dialog && e.target != yesBtn)
            return;
          dialog.removeEventListener("click", closeDialog);
          dialog.remove();
          acc(isAllCheck && e.target == yesBtn);
        }
        dialog.addEventListener("click", (event) => {
          closeDialog(event);
        });
      });
    }
    return await myPromiseGenerator();
  }

  buildModal(text, type) {
    const dialogWrapper = document.createElement("div");
    dialogWrapper.id = "dialogWrapper";
    const dialog = document.createElement("div");
    const dialogBody = document.createElement("div");
    const dialogFooter = document.createElement("footer");
    const dialogHeader = document.createElement("header");
    dialogBody.className = `dialog__body`;
    dialogFooter.className = `dialog__footer`;
    dialogHeader.className = `dialog__header dialog__box--${type}`;
    dialog.className = `dialog__box dialog__box--${type}`;
    dialogWrapper.className = `dialog__wrapper`;
    dialog.appendChild(dialogHeader);
    dialog.appendChild(dialogBody);
    dialog.appendChild(dialogFooter);
    dialogWrapper.appendChild(dialog);
    if (type == "confirm") {
      text.forEach((element, i) => {
        const iptContainer = document.createElement("div");
        iptContainer.style = "display: flex;margin-bottom: 0.5rem;";
        const ipt = document.createElement("input");
        ipt.setAttribute("type", "checkbox");
        ipt.id = `cbid-${i}`;
        const label = document.createElement("label");
        label.innerText = element;
        label.htmlFor = `cbid-${i}`;
        iptContainer.appendChild(ipt);
        iptContainer.appendChild(label);
        dialogBody.appendChild(iptContainer);
      });
      const yesBtn = document.createElement("button");
      const noBtn = document.createElement("button");
      yesBtn.id = "yesBtn";
      noBtn.id = "noBtn";
      yesBtn.disabled = true;
      yesBtn.textContent = "כן";
      noBtn.textContent = "לא";
      yesBtn.className = `btn btn-secondary`;
      noBtn.className = `btn btn-warning`;
      yesBtn.style = "width:40%";
      noBtn.style = "width:40%";
      dialogFooter.appendChild(yesBtn);
      dialogFooter.appendChild(noBtn);
      dialogHeader.innerText = "רגע, בואו נוודא";
    }
    if (type == "select") {
      const select = document.createElement("select");
      select.id = "select";
      text.forEach((element, i) => {
        const option = document.createElement("option");
        option.value = element.field;
        option.textContent = element.title;
        select.appendChild(option);
      });
      dialogBody.appendChild(select);
      const okBtn = document.createElement("button");
      okBtn.id = "okBtn";
      okBtn.textContent = "אישור";
      okBtn.className = `btn`;
      const cancelBtn = document.createElement("button");
      cancelBtn.id = "cancelBtn";
      cancelBtn.textContent = "ביטול";
      cancelBtn.className = `btn  btn-warning`;
      cancelBtn.style = "width:40%";
      okBtn.style = "width:40%";
      select.style = "width:100%";

      dialogFooter.appendChild(okBtn);
      dialogFooter.appendChild(cancelBtn);
      dialogHeader.innerText = "בחרו...";
    }
    if (type == "input") {
      const input = document.createElement("input");
      input.id = "input";
      input.type = text;
      dialogBody.appendChild(input);
      const okBtn = document.createElement("button");
      okBtn.id = "okBtn";
      okBtn.textContent = "אישור";
      okBtn.className = `btn`;
      const cancelBtn = document.createElement("button");
      cancelBtn.id = "cancelBtn";
      cancelBtn.textContent = "ביטול";
      cancelBtn.className = `btn  btn-warning`;
      cancelBtn.style = "width:40%";
      okBtn.style = "width:40%";
      input.style =
        "width:100%;width: 100%;padding: 12px;border: 1px solid #176e4d;border-radius: 5px;line-height: 24px;min-width: 100px;";
      dialogFooter.appendChild(okBtn);
      dialogFooter.appendChild(cancelBtn);
      dialogHeader.innerText = "ערכו...";
    }

    document.querySelector("main").appendChild(dialogWrapper);
    return dialogWrapper;
  }
  confirmList() {
    return [
      "האם עברת על הטופס תוספות?",
      "האם חזר כל הציוד?",
      "האם זוכו החדרים?",
      "האם זוכו כל הכיתות?",
    ];
  }

  toggleRow(e) {
    e.target.closest(".main-row").classList.toggle("open");
    e.target.closest(".main-row").nextElementSibling.classList.toggle("hide");
  }
}
